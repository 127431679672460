// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-vakansiyi-js": () => import("./../../../src/pages/blog/vakansiyi.js" /* webpackChunkName: "component---src-pages-blog-vakansiyi-js" */),
  "component---src-pages-dopomoha-index-js": () => import("./../../../src/pages/dopomoha/index.js" /* webpackChunkName: "component---src-pages-dopomoha-index-js" */),
  "component---src-pages-dyakuemo-js": () => import("./../../../src/pages/dyakuemo.js" /* webpackChunkName: "component---src-pages-dyakuemo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-v-budynok-js": () => import("./../../../src/pages/internet-v-budynok.js" /* webpackChunkName: "component---src-pages-internet-v-budynok-js" */),
  "component---src-pages-internet-v-kvartyru-js": () => import("./../../../src/pages/internet-v-kvartyru.js" /* webpackChunkName: "component---src-pages-internet-v-kvartyru-js" */),
  "component---src-pages-internet-v-ofis-js": () => import("./../../../src/pages/internet-v-ofis.js" /* webpackChunkName: "component---src-pages-internet-v-ofis-js" */),
  "component---src-pages-kontakty-js": () => import("./../../../src/pages/kontakty.js" /* webpackChunkName: "component---src-pages-kontakty-js" */),
  "component---src-pages-promo-js": () => import("./../../../src/pages/promo.js" /* webpackChunkName: "component---src-pages-promo-js" */),
  "component---src-pages-publichniy-dohovir-oferta-js": () => import("./../../../src/pages/publichniy-dohovir-oferta.js" /* webpackChunkName: "component---src-pages-publichniy-dohovir-oferta-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-sposoby-oplaty-js": () => import("./../../../src/pages/sposoby-oplaty.js" /* webpackChunkName: "component---src-pages-sposoby-oplaty-js" */),
  "component---src-pages-telebachennya-index-js": () => import("./../../../src/pages/telebachennya/index.js" /* webpackChunkName: "component---src-pages-telebachennya-index-js" */),
  "component---src-pages-video-index-js": () => import("./../../../src/pages/video/index.js" /* webpackChunkName: "component---src-pages-video-index-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/article-template.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-city-template-js": () => import("./../../../src/templates/city-template.js" /* webpackChunkName: "component---src-templates-city-template-js" */),
  "component---src-templates-shop-category-template-js": () => import("./../../../src/templates/shop-category-template.js" /* webpackChunkName: "component---src-templates-shop-category-template-js" */),
  "component---src-templates-shop-product-template-js": () => import("./../../../src/templates/shop-product-template.js" /* webpackChunkName: "component---src-templates-shop-product-template-js" */),
  "component---src-templates-tv-provder-tariff-template-js": () => import("./../../../src/templates/tv-provder-tariff-template.js" /* webpackChunkName: "component---src-templates-tv-provder-tariff-template-js" */),
  "component---src-templates-tv-provider-template-js": () => import("./../../../src/templates/tv-provider-template.js" /* webpackChunkName: "component---src-templates-tv-provider-template-js" */)
}

